/* Global styles */
* {
  font-family: sans-serif;
}

.alert {
  position: fixed;
  bottom: 0;
  width: 100%;
  z-index: 1050; /* Keeps it on top */
  margin: 0;
  border-radius: 0;
}

/* App container styling */
.App {
  min-height: 100vh;
  max-width: 100%;
  background-color: #f0f1f8;
  display: flex;
  flex-direction: column;
  align-items: center;
}

/* Navbar styling */
.navbar {
  display: flex;
  flex-direction: row;
  max-width: 100vw;
  background-color: #f0f1f8 !important;
}

nav a {
  padding-left: 1rem;
  color: rgba(255, 106, 0, 1.0) !important;
  font-size: large !important;
  margin-left: 10px;
}

nav a:hover {
  text-decoration: underline;
}

nav button, button {
  background-color: rgba(255, 106, 0, 1.0) !important;
  border-color: rgba(255, 106, 0, 1.0) !important;
}

nav img {
  height: 2rem;
}

.PrintforgersLogo {
  margin: 0;
}
