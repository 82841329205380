.InteractionWindow{
    display: flex;
    flex-direction: column;
    align-items: center;
    align-content: center;
    padding: 20px 0;
    padding-top: 0;

}

.UploadCard{
    display: flex;
    flex-direction: column;
    align-self: center;
    align-items: center;
    align-content: center;
    padding: 20px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  
}



.STLViewerArray{
    display: flex;
    flex-direction: row;
    width: 90%;
}

.STLViewerWindow{
    background-color: white;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin: 0;
    border-style: solid;
    margin-bottom: 1rem;
    border-radius: 1em;
    border-color: rgba(180, 180, 180, 0.5) !important;
    border-radius: 1rem;
    border-width: .1rem;
    box-shadow: 4px 3px 3px rgb(180, 180, 180);
}

.LeftSTLWindow{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 50%;
    height: 100%;
}
.STLViewerObject{
    width: 100%;
    height: 100%;
}

.LeftSTLWindow p{
    padding:0;
    margin:0;

}
.RightSTLWindow{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;
    width: 50%;
}

.Quantity{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.STLControls{
    display: flex;
    flex-direction: row;
}

.STLControls input{
    border-radius: 0;
    padding: 0;
    margin: 0 !important;
    text-align: center;

}


.MinusButton{
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
}
.PlusButton{
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
}

.HavingTrouble{
    padding-top:20px
}