.FAQ {
    display: flex;
    flex-direction: column;
    align-items: center;
    align-content: center;
    padding: 20px 0;
    padding-top: 0;
    margin: 0 20px; /* Adding left and right margin */
  }
  
  .HowToCard{
    padding: 20px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  }
  .FAQ a{
    text-decoration-color: rgb(255, 106, 0);
    color: rgb(255, 106, 0)
  }
  .faq-title {
    font-size: 2.5rem;
    font-weight: bold;
    color: #343a40;
  }
  
  .faq-text {
    font-size: 1.25rem;
    color: #6c757d;
    margin-top: 10px;
    text-align: left;
  }
  
  .link-button {
    color: #fff;
    text-decoration: none;
  }
  
  .link-button:hover {
    text-decoration: none;
    color: #fff;
  }
  
  .faq-section-title {
    font-size: 2rem;
    margin-bottom: 20px;
    color: #343a40;
  }
  
  .mt-3 {
    margin-top: 1rem;
  }
  
  .mt-4 {
    margin-top: 1.5rem;
  }
  
  .mt-5 {
    margin-top: 3rem;
  }
  
  .FAQ span img{
    width: 100%;
    border-style: solid;
    border-width: .01rem;
    border-color:#bbbbbb98;
  }