.Homepage {
  display: flex;
  flex-direction: column;
  align-items: center;
  align-content: center;
  justify-content: space-between;
  padding: 20px 0;
  padding-top: 0;
  min-height: calc(100vh - 60px); /* Subtract the navbar height from 100vh */
}

.HomepageStartingRow {
  margin-top: 50px;
}

.LeftStartingColumn {
  display: flex;
  flex-direction: row;
  align-content: center;
  align-items: center;
  justify-content: space-between;
}

.homepage-title {
  font-size: 2.5rem;
  font-weight: bold;
  color: #343a40;
}

.homepage-subtitle {
  font-size: 1.25rem;
  color: #6c757d;
  margin-top: 10px;
}

.link-button {
  color: #fff;
  text-decoration: none;
}

.link-button:hover {
  text-decoration: none;
  color: #fff;
}

.carousel-custom {
  width: 100%;
}



.carousel-item span {
  width: 100%;
}
.carousel-item span img{
  width: 100%;
}






.homepage-section-title {
  font-size: 2rem;
  margin-bottom: 20px;
  color: #343a40;
}

.homepage-feature {
  padding: 20px;
}

.homepage-feature h4 {
  font-size: 1.5rem;
  color: #FF6A00;
}

.homepage-feature p {
  font-size: 1rem;
  color: #6c757d;
}
.small-business-note{
  font-size: 1rem;
  color: #6c757d;
}
.homepage-call-to-action {
  font-size: 2rem;
  margin-top: 50px;
  color: #343a40;
}

.mt-3 {
  margin-top: 1rem;
}

.mt-4 {
  margin-top: 1.5rem;
}

.mt-5 {
  margin-top: 3rem;
}

.carousel-caption {
  color: black;
}

.InstantQuoteButton {
  width: 100%;
}

.ExampleFileButton {
  background-color: #f0f1f8 !important;
  border-color: #adadad !important;
  color: black;
  width: 100%;
}






.carousel-caption {
  background-color: rgba(0, 0, 0, 0.4);
  padding: 10px;
  border-radius: 5px; 
}

.carousel-caption h3 {
  font-size: 1.25rem;
  color: #fff; 
}

.carousel-caption p {
  font-size: .75rem; 
  color: #fff;
}

.CarouselDiv p, .CarouselDiv h3 {
  color: white;
}




.footer {
  padding-top: 100px;
  text-align: center;
  position: relative;
  width: 100%;
  bottom: 0;
  color: #6c757d;

}

.footer p {
  margin: 0;
}