.OptionWindow{
    display: flex;
    flex-direction: column;
    align-items: center;
    align-content: center;
    padding: 20px 0;
    padding-top: 0;
  
}

.OptionCard{
    display: flex;
    flex-direction: column;
    align-self: center;
    align-items: center;
    align-content: center;
    text-align: center;
    padding: 20px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    width: 100%;
}

.OptionChanger{
    display: flex;
    flex-direction: row;
    width: 100%;
}
.OptionChangerItem{
    display: flex;
    flex-direction: column;
    align-items: center;
    align-content: center;
    justify-content: center;
    margin-bottom: 1rem;
    background-color: white;
    border-style: solid;
    border-width: .1em;
    border-width: .01em;
    border-color: rgba(180, 180, 180, 0.5) !important;
    box-shadow: 4px 3px 3px rgb(180, 180, 180);
}
.OptionChangerItem p{
    margin: 0;
}
.OptionChangerItem:hover{
    cursor: pointer;
}

.MaterialAndColor{
    display: flex;
    flex-direction: row;

}

.Color .Material .Infill{
    display: flex;
    flex-direction: row;
}


.SelectMaterialList{
    display: flex;
    flex-direction: column;
    width: 100%;
}
.SelectMaterialList .MaterialListDescription{
    text-align: center;
}



.MaterialControls{
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 1rem;
}


.colorImage {
    border-style: solid;
    border-width: 1px;
    border-color: rgba(0, 0, 0, 0.15);
    height: 48px; /* Set the height to match the font size */
    border-radius: 4px;
    width: auto;  /* Maintain aspect ratio */
    margin-right: 0.5rem; /* Add some space between image and text */
    padding: 0;
}

.LeftColorColumn {
    display: flex;
    align-items: center;
    padding: 1rem;
}

.Description {
    display: flex;
    align-items: center; /* Center-align the text vertically with the image */
    margin: 0; /* Remove extra margin */
}

.Material p {
    margin: 0;
}

.SelectMaterialList {
    display: flex;
    flex-direction: column;
    width: 95%;
}

.Material {
    align-items: center;
    background-color: white;
    border-width: .1rem;
    border-width: .01em;
    border-style: solid;
    border-color: rgba(180, 180, 180, 0.5) !important;
    box-shadow: 4px 3px 3px rgb(180, 180, 180);
}
