.Cart{
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: white;
  box-shadow: 0 4px 8px rgba(0,0,0,0.1);
  padding: 20px 0;
  margin: 1rem;
}


.EmptyCart{
  display: flex;
  flex-direction: column;
  align-items: center;
  align-content: center;
  text-align: center;
  padding: 20px 0;
  padding-top: 0;
  font-size: 1.25rem;
  color: #6c757d;
}
.CartCard{
  width: 100%;
  padding: 20px;
  margin-top: 0px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}
  
hr {
  border: 0;
  height: 2px;
  width: 100%;
  background-color: #6e6e6e !important;
}


.STLListElement{
  display: flex;
  justify-content: space-between;
  width: 100%;
}
.StlViewerComponent{
  display: flex;
  flex-direction: column;
  justify-content: start;
}
.STLInfo{
  align-content: center;
}


.OptionListInCartRow {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
}




.ShippingInfo{
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: white;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  padding: 20px 0;
}

.Shipping-form {
  display: flex;
  flex-direction: column;
  width: 80%;
  gap: 10px;
}
.Shipping-form div{
  padding:0;
}

.Shipping-form label {
  font-weight: bold;
}

.Shipping-form input[type="text"], 
.Shipping-form input[type="email"], 
.Shipping-form input[type="tel"] {
  width: 100%;
  padding-top: 8px;
  padding-bottom: 8px;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.Shipping-form button {
  color: white;
  border: none;
  padding: 10px 20px;
  border-radius: 4px;
  cursor: pointer;
}

.Shipping-form button:hover {
  background-color: #84b9bb;
}

.LeftSideOrderInfo{
  text-align: left;
  align-self: center;
}
.RightSideOrderInfo{
  text-align: right;
  align-self: center;
}

.Cart button, .EmptyCart button, .ShippingInfo button{
  width: 160px;
  min-width: fit-content;
  align-self: center;
}
