.FAQ {
  display: flex;
  flex-direction: column;
  align-items: center;
  align-content: center;
}

.FAQCard{
    padding: 20px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}
.FAQ a{
  text-decoration-color: rgb(255, 106, 0);
  color: rgb(255, 106, 0)
}
.faq-title {
  font-size: 2.5rem;
  font-weight: bold;
  color: #343a40;
}

.faq-text {
  font-size: 1.25rem;
  color: #6c757d;
  margin-top: 10px;
  text-align: left;
}

.link-button {
  color: #fff;
  text-decoration: none;
}

.link-button:hover {
  text-decoration: none;
  color: #fff;
}

.faq-section-title {
  font-size: 2rem;
  margin-bottom: 20px;
  color: #343a40;
}

.FAQ span img{
  width: 100%;
}