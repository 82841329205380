.privacy-container {
    margin: 0 auto;
    padding:20px;
    padding-top:0;
  }
  
  .privacy-card {
    padding: 20px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  }
  
  .privacy-section {
    margin-bottom: 20px;
  }
  
  .privacy-section h2 {
    font-size: 1.5em;
    margin-bottom: 10px;
  }
  
  .privacy-section p {
    font-size: 1em;
    line-height: 1.5;
  }
  