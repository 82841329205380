.AboutUs {
  display: flex;
  flex-direction: column;
  align-items: center;
  align-content: center;
  padding: 20px 0;
  padding-top: 0;
}

.AboutUsCard{
  padding: 20px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.AboutUs a{
  text-decoration-color: rgb(255, 106, 0);
  color: rgb(255, 106, 0)
}

.aboutus-title {
  font-size: 2.5rem;
  font-weight: bold;
  color: #343a40;
}

.aboutus-text {
  font-size: 1.25rem;
  color: #6c757d;
  margin-top: 10px;
  text-align: left;
}

.link-button {
  color: #fff;
  text-decoration: none;
}

.link-button:hover {
  text-decoration: none;
  color: #fff;
}

.aboutus-section-title {
  font-size: 2rem;
  margin-bottom: 20px;
  color: #343a40;
}


.social-icon {
  margin: 0 10px;
  color: #343a40;
}

.social-icon:hover {
  color: #007bff;
}

@media (min-width: 768px) {
  .MiddleRow {
    flex-direction: row-reverse;
  }
}

.AboutUs span img{
  width: 100%;
}