.TermsWindow{
  display: flex;
  flex-direction: column;
  align-items: center;
  align-content: center;
  padding: 20px;
  padding-top: 0px;
}
  
.terms-card {
  padding: 20px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}